
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "tailwindcss/tailwind.css";

import { SessionProvider } from "next-auth/react";

import React from "react";
import { AppProps } from "next/app";
import Head from "next/head";

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps): JSX.Element {
  return (
    <SessionProvider session={session}>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
          key="viewport"
        />
        <link rel="icon" type="image/x-icon" href="/images/icons/favicon.ico" />
        <meta
          name="description"
          content="Behale designs solutions that shape the future of holistic wellness for everyone, leveraging both low-tech and cutting-edge technologies, to solve the toughest problems in the field."
        />
        <link rel="apple-touch-icon" href="/images/icons/logo192.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://rsms.me/inter/inter.css"
        />
        <meta name="application-name" content="Behale" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Behale" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#128D8F" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="apple-touch-icon" href="/images/icons/logo192.png" />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/images/icons/logo192.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/icons/logo192.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="167x167"
          href="/images/icons/logo192.png"
        />

        <link
          rel="icon"
          type="image/ico"
          sizes="32x32"
          href="/images/icons/favicon.ico"
        />
        <link
          rel="icon"
          type="image/ico"
          sizes="16x16"
          href="/images/icons/favicon.ico"
        />
        <link
          rel="mask-icon"
          href="/images/icons/logo192.png"
          color="#5bbad5"
        />
        {/* <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://yourdomain.com" />
        <meta name="twitter:title" content="PWA App" />
        <meta name="twitter:description" content="Best PWA App in the world" />
        <meta
          name="twitter:image"
          content="https://yourdomain.com/icons/android-chrome-192x192.png"
        />
        <meta name="twitter:creator" content="@DavidWShadow" /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Behale" />
        <meta
          property="og:description"
          content="Behale designs solutions that shape the future of holistic wellness for everyone, leveraging both low-tech and cutting-edge technologies, to solve the toughest problems in the field."
        />
        <meta property="og:site_name" content="Behale" />
        <meta property="og:url" content="https://behale.io" />
        <meta
          property="og:image"
          content="https://behale.io/icons/logo192.png"
        />

        <title>Behale</title>
      </Head>
      <Component {...pageProps} />
    </SessionProvider>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  